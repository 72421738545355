


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
// import CompanyDetails from '../../Customers/СompanyDetails/СompanyDetails.vue';

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'documents', 'lastMeta',
    ]),
  },
  methods: {
    getFieldError,
  },
  components: {
    TitleBar,
  },
})
export default class Notifications extends Vue {
  public showModal: any = false

  get titleStack() {
    return [
      'Сообщения',
    ]
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  public openDocument(row: any) {
    this.$store.dispatch(`${STORE_KEY}/markReaded`, row.id)
  }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.params)
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
